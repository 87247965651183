import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import Image from "next/image";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";
import Link from "next/link";
import { authUrl, redirectUri } from "src/api/config";
import { DiscountContext } from "../context/DiscountContext";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "next-i18next";

export default function Hero() {
  const { t } = useTranslation("common");
  const { user } = React.useContext(UserContext);

  const { discount } = React.useContext(DiscountContext);

  const price =
    process.env.NEXT_PUBLIC_COMPANY_NAME === "legalis"
      ? `${discount ? Math.floor(discount.hourPrice * 2) : 2300}`
      : "2.300 eks. mva. (2.875 inkl. mva.)";

  return (
    <Box as="section" pt="16" pb="24">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "8" }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: "3rem", lg: "2rem" }}
          mt="8"
          align={{ lg: "center" }}
          justify="space-between"
        >
          <Box flex="1" maxW={{ lg: "520px" }}>
            <Text
              size="xs"
              textTransform="uppercase"
              fontWeight="semibold"
              color="legalis.500"
              letterSpacing="wide"
            >
              {t("company-name")}
            </Text>
            {discount && (
              <Alert mt="5" status="success" mb="10">
                <AlertIcon />
                Gjennom {discount.name} får du en rabattert timepris på{" "}
                {discount.hourPrice * 2} kroner.
              </Alert>
            )}
            <Heading
              as="h1"
              size="3xl"
              mt="8"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Dyktige advokater.
              <br />
              Rett i nettleseren.
            </Heading>
            <Text
              color={mode("gray.600", "gray.400")}
              mt="4"
              fontSize="lg"
              fontWeight="medium"
            >
              Advokattjenester trenger ikke å ha høy terskel. Vi tilbyr våre til
              kun kr {price} per time for advokat, og du velger selv om du vil
              møte oss over videosamtaler, telefonsamtaler eller fysisk i Oslo.
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing="4" mt="8">
              <Link href="/timebestilling">
                <Button
                  size="lg"
                  minW="210px"
                  colorScheme="legalis"
                  w="100%"
                  aria-label="Kom igang med bestilling"
                  height="14"
                  px="8"
                >
                  Kom igang
                </Button>
              </Link>
              <Button
                size="lg"
                bg="white"
                as="a"
                aria-label="Til Legalis sine hovedsider"
                href={process.env.NEXT_PUBLIC_MAIN_URL}
                color="gray.900"
                _hover={{ bg: "gray.50" }}
                height="14"
                px="8"
                w="100%"
                shadow="base"
                rightIcon={<Box as={BsArrowRight} fontSize="2xl" />}
              >
                Til våre hovedsider
              </Button>
            </Stack>
            {!discount && !user && (
              <Text mt="4" fontWeight="600">
                Første 15 minutter gratis.
              </Text>
            )}

            {!user && (
              <Text mt="4" color={mode("gray.600", "gray.400")}>
                Har du allerede en sak?{" "}
                <Link
                  href={`${authUrl}/dialog/authorize?client_id=timebooking&response_type=code&redirect_uri=${redirectUri}`}
                >
                  Logg inn
                </Link>
              </Text>
            )}
          </Box>
          <Flex
            pos="relative"
            w={{ base: "full", lg: "560px" }}
            h={{ base: "full", lg: "560px" }}
            zIndex="1"
          >
            <Box
              pos="absolute"
              w="100%"
              h="100%"
              zIndex="-1"
              top="-4"
              left="-4"
            />
            <Image
              src="/images/grid.jpg"
              layout="fill"
              objectFit={"contain"}
              alt="Bilde av ansatte i Legalis"
            />
          </Flex>
        </Stack>
      </Box>
    </Box>
  );
}
